import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

import { LoginComponent } from './login';
import { AuthGuard } from './_guards';

export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
        canActivate: [AuthGuard]
    },
    { path: 'login', component: LoginComponent },
    { path: 'transfer', loadChildren: './transfer/transfer.module#TransferModule' },
    {
        path: '',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: './dashboard/dashboard.module#DashboardModule'
            }, {
                path: 'tabungan-laporan',
                loadChildren: './modules/tabungan/laporan/laporan.module#LaporanModule'
            }, {
                path: 'tabungan-teller',
                loadChildren: './modules/tabungan/teller/teller.module#TellerModule'
            }, {
                path: 'tabungan-nasabah',
                loadChildren: './modules/tabungan/nasabah/nasabah.module#NasabahModule'
            }, {
                path: 'tabungan-setup',
                loadChildren: './modules/tabungan/setup/setup.module#SetupModule'
            }, {
                path: 'kopontren',
                loadChildren: './modules/kopontren/kopontren.module#KopontrenModule'
            }, {
                path: 'rapor',
                loadChildren: './modules/rapor/rapor.module#RaporModule'
            }, {
                path: 'matapelajaran',
                loadChildren: './modules/matapelajaran/matapelajaran.module#MatapelajaranModule'
            }, {
                path: 'sdm',
                loadChildren: './modules/sdm/sdm.module#SdmModule'
            }, {
                path: 'client',
                loadChildren: './modules/client/client.module#ClientModule'
            }, {
                path: 'order',
                loadChildren: './modules/order/order.module#OrderModule'
            }, {
                path: 'invoice',
                loadChildren: './modules/invoice/invoice.module#InvoiceModule'
            }, {
                path: 'offering',
                loadChildren: './modules/offering/offering.module#OfferingModule'
            }, {
                path: 'departure',
                loadChildren: './modules/departure/departure.module#DepartureModule'
            }, {
                path: 'booking',
                loadChildren: './modules/booking/booking.module#BookingModule'
            }, {
                path: 'operation',
                loadChildren: './modules/operation/operation.module#OperationModule'
            }, {
                path: 'travel-paket',
                loadChildren: './modules/travel-paket/travel-paket.module#TravelPaketModule'
            }, {
                path: 'paket-produk',
                loadChildren: './modules/paket-produk/paket-produk.module#PaketProdukModule'
            }, {
                path: 'vendor',
                loadChildren: './modules/vendor/vendor.module#VendorModule'
            }, {
                path: 'vendor/product-category',
                loadChildren: './modules/product-category/product-category.module#ProductCategoryModule'
            }, {
                path: 'vendor/type',
                loadChildren: './modules/vendor-type/vendor-type.module#VendorTypeModule'
            }, {
                path: 'vendor-produk',
                loadChildren: './modules/vendor-produk/vendor-produk.module#VendorProdukModule'
            }, {
                path: 'user',
                loadChildren: './modules/user/user.module#UserModule'
            }, {
                path: 'user/group',
                loadChildren: './modules/usergroup/usergroup.module#UsergroupModule'
            }, {
                path: 'edit-profile',
                loadChildren: './modules/edit-profile/edit-profile.module#EditProfileModule'
            }, {
                path: 'menu',
                loadChildren: './modules/menu/menu.module#MenuModule'
            }, {
                path: 'components',
                loadChildren: './components/components.module#ComponentsModule'
            }, {
                path: 'tfa-news',
                loadChildren: './modules/tfa-news/tfa-news.module#TfaNewsModule'
            }, {
                path: 'tfa-sosmed',
                loadChildren: './modules/tfa-sosmed/tfa-sosmed.module#TfaSosmedModule'
            }, {
                path: 'master-data',
                loadChildren: './modules/master-data/master-data.module#MasterDataModule'
            }, {
                path: 'forms',
                loadChildren: './forms/forms.module#Forms'
            }, {
                path: 'tables',
                loadChildren: './tables/tables.module#TablesModule'
            }, {
                path: 'maps',
                loadChildren: './maps/maps.module#MapsModule'
            }, {
                path: 'widgets',
                loadChildren: './widgets/widgets.module#WidgetsModule'
            }, {
                path: 'charts',
                loadChildren: './charts/charts.module#ChartsModule'
            }, {
                path: 'calendar',
                loadChildren: './calendar/calendar.module#CalendarModule'
            }, {
                path: '',
                loadChildren: './userpage/user.module#UserModule'
            }, {
                path: '',
                loadChildren: './timeline/timeline.module#TimelineModule'
            }, {
                path: 'client-order',
                loadChildren: './modules/client-order/client-order.module#ClientOrderModule'
            }
        ]
    }, {
        path: '',
        canActivate: [AuthGuard],
        component: AuthLayoutComponent,
        children: [{
            path: 'pages',
            loadChildren: './pages/pages.module#PagesModule'
        }]
    },
    // otherwise redirect to home
    { path: '**', redirectTo: 'dashboard' }
];
