import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagging',
  templateUrl: './pagging.component.html',
  styleUrls: ['./pagging.component.css']
})
export class PaggingComponent implements OnInit {
  @Input() tableSvc: any;
  // @Output() changelimit: EventEmitter<any> = new EventEmitter();
  // @Output() topage: EventEmitter<any> = new EventEmitter();
  
  constructor() {}

  ngOnInit() {
    // this.metadata.startRow = this.metadata.skip + 1;
    // this.metadata.endRow = this.metadata.skip + this.metadata.limit;
    // this.metadata.page = (this.metadata.skip / this.metadata.limit) + 1;
    // console.log("this.metadata", this.metadata)
  }

  // onchangelimit(): void { this.changelimit.emit(this.metadata.limit); }
  // goToPage(p): void { this.topage.emit(p); }

}
