import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { AuthenticationService } from '@app/_services';
import * as jwt_decode from "jwt-decode"
import { DataService } from '@app/_services/data.service'
import * as moment from 'moment'

import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { User } from '@app/_models';
import { environment } from '@environments/environment';

declare const $: any;

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
    collapse?: string;
    children?: ChildrenItems[];
}

//Menu Items
export const ROUTES: RouteInfo[] = [{
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'dashboard'
}, {
    path: '/offering',
    title: 'Penawaran',
    type: 'link',
    icontype: 'receipt'
}, {
    path: '/departure',
    title: 'Group Keberangkatan',
    type: 'link',
    icontype: 'group'
}, {
    path: '/order/',
    title: 'Order',
    type: 'link',
    icontype: 'assignment'
}, {
    path: '/operation/update/',
    title: 'Operation',
    type: 'link',
    icontype: 'assignment_turned_in'
}, {
    path: '/invoice/',
    title: 'Invoice',
    type: 'link',
    icontype: 'description'
}, {
    path: '/',
    title: 'Master',
    type: 'sub',
    icontype: 'apps',
    collapse: 'master',
    children: [
        {
            path: 'travel-paket',
            title: 'Paket',
            type: 'link',
            ab: 'P'
        },
        {
            path: 'user',
            title: 'User',
            ab: 'U',
            type: 'sub',
            collapse: 'user',
            children: [
                { path: 'list', title: 'List', ab: 'L' },
                { path: 'group', title: 'Group', ab: 'G' }
            ]
        }, {
            path: 'client',
            title: 'Client',
            type: 'link',
            ab: 'C'
        }, {
            path: 'vendor',
            title: 'Vendor',
            ab: 'V',
            type: 'sub',
            collapse: 'vendor',
            children: [
                { path: 'list', title: 'List', ab: 'L' },
                { path: 'product-category', title: 'Kategori Produk', ab: 'KP' }
            ]
        }
    ]
}, /*{
    path: '/components',
    title: 'Components',
    type: 'sub',
    icontype: 'apps',
    collapse: 'components',
    children: [
        { path: 'buttons', title: 'Buttons', ab: 'B' },
        { path: 'grid', title: 'Grid System', ab: 'GS' },
        { path: 'panels', title: 'Panels', ab: 'P' },
        { path: 'sweet-alert', title: 'Sweet Alert', ab: 'SA' },
        { path: 'notifications', title: 'Notifications', ab: 'N' },
        { path: 'icons', title: 'Icons', ab: 'I' },
        { path: 'typography', title: 'Typography', ab: 'T' }
    ]
}, {
    path: '/forms',
    title: 'Forms',
    type: 'sub',
    icontype: 'content_paste',
    collapse: 'forms',
    children: [
        { path: 'regular', title: 'Regular Forms', ab: 'RF' },
        { path: 'extended', title: 'Extended Forms', ab: 'EF' },
        { path: 'validation', title: 'Validation Forms', ab: 'VF' },
        { path: 'wizard', title: 'Wizard', ab: 'W' }
    ]
}, {
    path: '/tables',
    title: 'Tables',
    type: 'sub',
    icontype: 'grid_on',
    collapse: 'tables',
    children: [
        { path: 'regular', title: 'Regular Tables', ab: 'RT' },
        { path: 'extended', title: 'Extended Tables', ab: 'ET' },
        { path: 'datatables.net', title: 'Datatables.net', ab: 'DT' }
    ]
}, {
    path: '/maps',
    title: 'Maps',
    type: 'sub',
    icontype: 'place',
    collapse: 'maps',
    children: [
        { path: 'google', title: 'Google Maps', ab: 'GM' },
        { path: 'fullscreen', title: 'Full Screen Map', ab: 'FSM' },
        { path: 'vector', title: 'Vector Map', ab: 'VM' }
    ]
}, {
    path: '/widgets',
    title: 'Widgets',
    type: 'link',
    icontype: 'widgets'

}, {
    path: '/charts',
    title: 'Charts',
    type: 'link',
    icontype: 'timeline'

}, {
    path: '/calendar',
    title: 'Calendar',
    type: 'link',
    icontype: 'date_range'
}, {
    path: '/pages',
    title: 'Pages',
    type: 'sub',
    icontype: 'image',
    collapse: 'pages',
    children: [
        { path: 'pricing', title: 'Pricing', ab: 'P' },
        { path: 'timeline', title: 'Timeline Page', ab: 'TP' },
        { path: 'login', title: 'Login Page', ab: 'LP' },
        { path: 'register', title: 'Register Page', ab: 'RP' },
        { path: 'lock', title: 'Lock Screen Page', ab: 'LSP' },
        { path: 'user', title: 'User Page', ab: 'UP' }
    ]
}*/
];
@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})



export class SidebarComponent implements OnInit {
    public menuItems: any[];
    currentUser: User;
    user: any = { username: '' }
    public menuItemsData: any = []
    userLogin;
    namaSekolah = ''
    timer: any = ''
    countDown: any = null

    constructor(
        private dataSvc: DataService,
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
        this.userLogin = JSON.parse(sessionStorage.loginUser)
        this.userLogin.admin = this.userLogin.merchan ? false : true
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        const decodedToken = jwt_decode(this.currentUser.token)
        this.user = decodedToken.user
        this.getTreeMenu();
        setTimeout(() => this.getTreeMenu(), 500);
        if(this.user.uid_sekolah) this.getSekolah()
        this.setTimer()
        this.bodyMove()
    }

    bodyMove() {
        var thisClass = this
        const mypage = document.getElementById('mypage')
        const listeners = ['mousemove', 'mousedown', 'mouseup', 'keydown', 'keypress', 'keyup']
        listeners.forEach(l => {
            mypage.addEventListener(l, e => {
                clearInterval(thisClass.countDown)
                thisClass.setTimer()
            })
        })
    }

    setTimer() {
        var a: any = moment().add(10.02, "minutes")
        this.countDown = setInterval(() => {
            var b: any = moment()
            var distance = a - b

            var minutes: any = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
            var seconds: any = Math.floor((distance % (1000 * 60)) / 1000)
            minutes = minutes < 10 ? `0${minutes}` : minutes
            seconds = seconds < 10 ? `0${seconds}` : seconds

            this.timer = `${minutes}:${seconds}`

            if(distance <= 0) {
                clearInterval(this.countDown)
                this.logout()
            }
        }, 1000)
    }

    getTreeMenu() {
        const params = {};
        this.dataSvc.get(`/usergroup/treemenu?isUser=1&idUserGroup=0&aplikasi=${environment.aplikasi}`, params).subscribe(
            res => {
                // console.log('res /usergroup/treemenu', res);
                this.menuItemsData = res.data;
                this.menuItemsData.map(n => {
                    if (n.icon == '' && n.name == 'Teller') n.icon = 'add_task'
                    if (n.icon == '' && n.name == 'Nasabah') n.icon = 'account_box'
                    if (n.icon == '' && n.name == 'Setup') n.icon = 'settings_applications'
                    if (n.icon == '' && n.name == 'Laporan') n.icon = 'addchart'
                    if (n.icon == '' && n.name == 'Kopontren') n.icon = 'work'
                })

                const myGroup = $('#leftNav');
                myGroup.on('show.bs.collapse', '.collapse', function () {
                    // console.log('>> yuhuuu')
                    myGroup.find('.collapsing').collapse('hide');
                    // myGroup.find('.collapsing').css({"height": "0"});;
                });

            },
            error => {
                console.log('error', error);
            }
        );
    }

    updatePS(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            let ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: true });
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    getAcronym(str: String) {
        const matches = str.match(/\b(\w)/g)
        const acronym = matches.join('')
        return acronym
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }

    getSekolah() {
        if(localStorage.dataSekolah) {
            const data = JSON.parse(localStorage.dataSekolah)
            this.namaSekolah = data['nama']
            return
        }
        this.dataSvc.get(`/sekolah/${this.user.uid_sekolah}`,)
        .subscribe(res => {
            if(res['status'] == 'success') {
                localStorage.dataSekolah = JSON.stringify(res['data'])
                this.namaSekolah = res['data']['nama']
            }
        })
    }
}
