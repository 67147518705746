import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule  } from '@angular/forms';
import { MaterialModule } from '../app.module';

import { TableComponent } from './table/table.component';
// import { FormComponent } from './form/form.component'
// import { SearchComponent } from './search/search.component'
import { PaggingComponent } from './pagging/pagging.component';
import { MenuDetailDepartureComponent } from './menu-detail-departure/menu-detail-departure.component';
import { filterPipe } from './filter.pipe'

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [
    PaggingComponent,
    TableComponent,
    MenuDetailDepartureComponent,
    filterPipe,
  ],
  exports: [
    PaggingComponent,
    TableComponent,
    MenuDetailDepartureComponent,
    filterPipe
  ],
})

export class SharedModule { }
