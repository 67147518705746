import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-detail-departure',
  templateUrl: './menu-detail-departure.component.html',
  styleUrls: ['./menu-detail-departure.component.css']
})
export class MenuDetailDepartureComponent implements OnInit {
  @Input() id: number;
  active;
  menus;
  userLogin;

  constructor(
    private router: Router
  ) {
    this.userLogin = JSON.parse(localStorage.loginUser)
    this.userLogin.admin = this.userLogin.merchan ? false : true
  }

  ngOnInit() {
    this.active = this.router.url.split('/')[2];
    if (this.active == 'manifest-form') this.active = 'manifest'
    
    this.menus = []
    this.menus.push({route: 'detail', label: 'Detail'});
    this.menus.push({route: 'flight', label: 'Flight'});
    this.userLogin.admin && this.menus.push({route: 'order', label: 'Order'});
    this.userLogin.admin && this.menus.push({route: 'invoice', label: 'Invoice'});
    this.userLogin.admin && this.menus.push({route: 'booking', label: 'Booking'});
    this.menus.push({route: 'itinerary', label: 'Itinerary'});
    this.menus.push({route: 'manifest', label: 'Manifest'});
    this.menus.push({route: 'file', label: 'Document'});
    
  }

  toPage(page) {
    console.log(page);
    this.router.navigate([page + this.id]);
  }

}
