import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import swal from 'sweetalert2';
import { Router, ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(
    private http: HttpClient,
    private router: Router,
  ) {}

  table() {}

  async asyncPost(endPoint: string, params: any = {}) 
  {
    return await this.http.post(`${environment.apiUrl}` + endPoint, params).toPromise();
  }
  async asyncGet(endPoint: string, params: any = {}) 
  {
    return await this.req('GET', endPoint, params).toPromise();
  }

  get(endPoint: string, params: any = {}) {
    return this.req('GET', endPoint, params)
  }
  post(endPoint: string, params: any = {}) {
    // return this.req('POST', endPoint, params)
    return this.http.post(`${environment.apiUrl}` + endPoint, params);
  }
  put(endPoint: string, params: any = {}) {
    return this.req('PUT', endPoint, params);
  }
  delete(endPoint: string, params: any = {}) {
    return this.req('DELETE', endPoint, params);
  }

  private req(method: string, endPoint: string, params: any = {}) {

    const options = { reportProgress: true, params };
    return this.http.request<any>(method, `${environment.apiUrl}${endPoint}`, options);
  }

  onSuccess(message: string) {
    swal({
      title: 'Success',
      text: message,
      timer: 1500,
      showConfirmButton: false,
      type: 'success'
    }).catch(swal.noop);
  }

  handleResponse(res: any, successMessage: any, redirectRoute: any, cb = null) {
    if(res['status'] == 'error'){
      const errorText = res['message']['message'] || (res['message'])
        swal({
          title: "Oops",
          text: JSON.stringify(errorText),
          buttonsStyling: false,
          confirmButtonClass: "btn btn-info",
          type: "error"
        }).catch(swal.noop)
    } else {
      this.onSuccess(successMessage || 'Sukses')
      if(redirectRoute) this.router.navigate(redirectRoute)
      if(cb) cb()
    }
  }
}
