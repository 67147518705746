import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter',
    pure: false
})
export class filterPipe implements PipeTransform {
    transform(items: any[], filter: Object): any {
        if (!items || !filter) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        const exp = filter['key'].split('.')
        return exp.length == 1 
        ? items.filter(item => item[exp[0]].toUpperCase().indexOf(filter['kw'].toUpperCase()) !== -1)
        : items.filter(item => item[exp[0]][exp[1]].toUpperCase().indexOf(filter['kw'].toUpperCase()) !== -1);
    }
}