import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

import { AuthenticationService } from './_services';

import { User } from './_models';

@Component({
  selector: 'app-my-app',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
  currentUser: User;
  private _router: Subscription;
  currentRoute: any = null
  showLoadingPage = false

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    router.events.filter(event => event instanceof NavigationStart).subscribe((event: NavigationStart) => {
      if(!this.currentRoute || !this.currentUser) {
        this.showLoadingPage = false
      }
      if(this.currentUser) {
        if(this.currentRoute && this.currentRoute != event.url) {
          this.showLoadingPage = true
        }
      }
      this.currentRoute = event.url
    })
    router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
      setTimeout(() => {
        this.showLoadingPage = false
      }, 123)
    })
  }

  ngOnInit() {
    this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
      const body = document.getElementsByTagName('body')[0];
      const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
      if (body.classList.contains('modal-open')) {
        body.classList.remove('modal-open');
        modalBackdrop.remove();
      }
    });
    console.log('app...')
  }

}
