import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor() {}

  hide(selector: any) {
    const modal = document.querySelector(selector)
    // var me = document.createEvent('MouseEvent')
    // me.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
    modal.dispatchEvent(new MouseEvent('click'))
  }

}