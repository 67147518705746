import { Component, OnInit, Input, Output, EventEmitter, KeyValueDiffers } from '@angular/core';
import { Router } from "@angular/router"

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {
  objectKeys = Object.keys;
  @Output() fncRemove: EventEmitter<any> = new EventEmitter();
  @Input() tableSvc: any;
  @Input() settings: object;
  columns: object;
  tools: object;
  differ: any;
  source = [];
  display = [];

  constructor(
    private differs: KeyValueDiffers,
    private router: Router
  ) {
    this.differ = this.differs.find({}).create();
  }

  ngOnInit() {
    this.columns = this.settings ? this.settings['columns'] : [];
    this.tools = this.settings ? this.settings['tools'] : {};
  }

  ngDoCheck() {
    this.source = this.tableSvc.datas
    const change = this.differ.diff(this);
    if (change) {
      change.forEachChangedItem(item => {
        // console.log('item changed', item);
        if (item.key == 'source') this.dataMapping()
      });
    }
  }

  dataMapping() {
    // console.log('source', this.source)
    this.display = []
    this.source.forEach(e => {
      let fDisplay = {}
      for (let c in this.settings['columns']) {
        let dDisplay = e[c];
        if (this.columns[c].type == 'date' && dDisplay) dDisplay = dDisplay!='0000-00-00 00:00:00' ?  this.formatDate(e[c]) : ''
        if (this.columns[c].child && dDisplay) dDisplay = e[c][this.columns[c].child.label]
        if (this.columns[c].as && dDisplay) dDisplay = this.columns[c].as[e[c]]
        fDisplay[c] = dDisplay
        // console.log('==>', e[c], dDisplay)
      }
      this.display.push(fDisplay);
      // console.log('============')
    });
    console.log('dataDisplay ==> ', this.display)
  }

  formatDate(d) {
    let date = new Date(d);
    const month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    return this.addZero(date.getDate()) + ' ' + month_names_short[date.getMonth()] + ' ' + date.getFullYear() + ' ' + this.addZero(date.getHours()) + ':' + this.addZero(date.getMinutes());
  }

  addZero(i) {
    if (i < 10) { i = "0" + i; }
    return i;
  }

  toPage(page, id){
    console.log('toPage: ', page.replace("[id]", id), id)
    this.router.navigate([page.replace("[id]", id)])
  }

  toForm(id='') {
    this.router.navigate([`/${this.router.url.split("/")[1]}/form`, id])
  }

  removeData(id): void { this.fncRemove.emit(id); }

}
