import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NominalService {

  constructor() {}

    terbilang(terbilang: any){
        var minus = ''
        if(terbilang < 0) {
            minus = 'Minus '
            terbilang = Math.abs(terbilang)
        }
        var bilangan = `${terbilang || ''}`
        var kalimat="";
        var angka   = new Array('0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0');
        var kata    = new Array('','Satu','Dua','Tiga','Empat','Lima','Enam','Tujuh','Delapan','Sembilan');
        var tingkat = new Array('','Ribu','Juta','Milyar','Triliun');
        var panjang_bilangan = bilangan.length;
    
        /* pengujian panjang bilangan */
        if(panjang_bilangan > 15){
            kalimat = "Diluar Batas";
        }else{
            /* mengambil angka-angka yang ada dalam bilangan, dimasukkan ke dalam array */
            for(i = 1; i <= panjang_bilangan; i++) {
                angka[i] = bilangan.substr(-(i),1);
            }
            
            var i = 1;
            var j = 0;
            
            /* mulai proses iterasi terhadap array angka */
            while(i <= panjang_bilangan){
                var subkalimat = "";
                var kata1 = "";
                var kata2 = "";
                var kata3 = "";
                
                /* untuk Ratusan */
                if(angka[i+2] != "0"){
                    if(angka[i+2] == "1"){
                        kata1 = "Seratus";
                    }else{
                        kata1 = kata[angka[i+2]] + " Ratus";
                    }
                }
                
                /* untuk Puluhan atau Belasan */
                if(angka[i+1] != "0"){
                    if(angka[i+1] == "1"){
                        if(angka[i] == "0"){
                            kata2 = "Sepuluh";
                        }else if(angka[i] == "1"){
                            kata2 = "Sebelas";
                        }else{
                            kata2 = kata[angka[i]] + " Belas";
                        }
                    }else{
                        kata2 = kata[angka[i+1]] + " Puluh";
                    }
                }
                
                /* untuk Satuan */
                if (angka[i] != "0"){
                    if (angka[i+1] != "1"){
                        kata3 = kata[angka[i]];
                    }
                }
                
                /* pengujian angka apakah tidak nol semua, lalu ditambahkan tingkat */
                if ((angka[i] != "0") || (angka[i+1] != "0") || (angka[i+2] != "0")){
                    subkalimat = kata1+" "+kata2+" "+kata3+" "+tingkat[j]+" ";
                }
                
                /* gabungkan variabe sub kalimat (untuk Satu blok 3 angka) ke variabel kalimat */
                kalimat = subkalimat + kalimat;
                i = i + 3;
                j = j + 1;
            }
            
            /* mengganti Satu Ribu jadi Seribu jika diperlukan */
            if ((angka[5] == "0") && (angka[6] == "0")){
                kalimat = kalimat.replace("Satu Ribu","Seribu");
            }

            if(kalimat) {
                kalimat = `${minus}${kalimat}`
                kalimat += ' Rupiah'
            }
        }

        return kalimat
    }

}