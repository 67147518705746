﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as jwt_decode from "jwt-decode"

import { environment } from '@environments/environment';
import { User } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(identifier: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/user/token`, { identifier, password, uid_sekolah : environment.uid_sekolah})
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    const decodedToken = jwt_decode(user.token)
                    console.log('>> user', decodedToken)
                    sessionStorage.setItem('currentUser', JSON.stringify(user));
                    sessionStorage.setItem('loginUser', JSON.stringify(decodedToken.user));
                    this.currentUserSubject.next(user);
                }

                return user;
            }));
    }

    loginByToken(tokenString: string) {
        return this.http.post<any>(`${environment.apiUrl}/user/login-bytoken`, { tokenString })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    const decodedToken = jwt_decode(user.token)
                    console.log('>> user', decodedToken)
                    sessionStorage.setItem('currentUser', JSON.stringify(user));
                    sessionStorage.setItem('loginUser', JSON.stringify(decodedToken.user));
                    this.currentUserSubject.next(user);
                }

                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        sessionStorage.removeItem('currentUser');
        sessionStorage.removeItem('loginUser');
        this.currentUserSubject.next(null);
    }
}
